import { translate as t } from '@/util/i18n';
import isEmailValid from '@/lib/util/isEmailValid';

const isRequired = (name, value) =>
  !!value ||
  t('isRequired', null, {
    value: t(name),
  });

const minimum = (name, value, characters) =>
  (!!value && value.length >= characters) ||
  t('mustBeMinimum', null, {
    value: t(name),
    characters,
  });

const maximum = (name, value, characters) =>
  (!!value && value.length <= characters) ||
  t('mustBeMaximum', null, {
    value: t(name),
    characters,
  });

const isValid = (name, value, valid) =>
  valid ||
  t('isInvalid', null, {
    value: t(name),
  });

export const passwordRules = () => [(password) => minimum('password', password, 8)];

export const nameRules = () => [
  (name) => isRequired('name', name),
  (name) => minimum('name', name, 3),
  (name) => maximum('name', name, 100),
];

export const emailRules = (getMemberByEmail) => [
  (email) => isRequired('email', email),
  (email) => isValid('email', email, Boolean(isEmailValid(email))),
  (email) => {
    const member = getMemberByEmail(email);
    return member ? t('emailAlreadyInCompany', null, { userName: member.name }) : true;
  },
];

export const phoneRules = (getMemberByPhone) => [
  (phone) => isRequired('phone', phone && phone.number),
  (phone) =>
    isValid('phone', phone && phone.complete, Boolean(phone && phone.extension && phone.number)),
  (phone) => {
    if (!phone) {
      return false;
    }
    const member = getMemberByPhone(phone.complete);
    return member ? t('phoneAlreadyInCompany', null, { userName: member.name }) : true;
  },
];

export const teamRules = () => [(teamIds) => teamIds.length >= 1 || t('selectAtLeastOneTeam')];
